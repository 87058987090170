import { Component, OnInit, Input } from "@angular/core";
import { CartComponent } from "../cart/cart.component";
import { ModalController, ToastController } from "@ionic/angular";
import { Product } from "../../models/product.model";
import { ProductsService } from "../../services/products.service";
import { GlobalService } from "../../services/global.service";
import { CartService } from "./../../services/cart.service";
import { ApiService } from "../../api.service";
import { Carro } from "./../../models/carro.model";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

@Component({
    selector: "app-product-details",
    templateUrl: "./product-details.component.html",
    styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit {
    @Input() producto: any;
    codigo;
    //products: Product[];
    resultado = "";
    items: Product[];
    cartProducts: Carro;
    nophoto = "";

    // Slider Options
    slideOpts = {
        initialSlide: 0,
        loop: false,
        autoplay: false,
        speed: 400,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
    };

    constructor(    private cartService: CartService, 
                    private router: Router,
                    public global: GlobalService, public productsService: ProductsService, public modalController: ModalController, public apiService: ApiService, public toastController: ToastController) {}

    ngOnInit() {
        console.log(this.producto);
        console.log(this.global.products);
        //this.getProductList(this.codigo);
        this.nophoto = this.global.url + "imagenes/NOFOTO.JPG";
        this.getProductRel(this.producto.codigo);
        console.log(this.producto);
    }

    // Get List of Products
    /*getProductList(codigo) {
        console.log(codigo);
        this.productsService.producto(codigo).then((products) => {
            this.products = products;
            console.log(this.products);
        });
    }*/
    

    // Get List of Products
    getProductRel(codigo) {
        console.log(codigo);
        this.productsService.productRel(codigo).then((products) => {
            this.items = products;
            console.log(this.items);
           
        });
    }
    onError(item) {
        console.log(item.imagen);
        item.imagen = this.nophoto;
    }
    // Go to product details page
    async goToProductDetails(item1) {
    
        this.dismiss();
        const modal = await this.modalController.create({
            component: ProductDetailsComponent,
            componentProps: { producto: item1 },
        });
        return await modal.present();
    }

    // Go to cart page
    async gotoCartPage() {
        this.dismiss();
        const modal = await this.modalController.create({
            component: CartComponent,
        });
        
        return await modal.present();
    }

    // Añadir a favoritos
    async FavAdd(articulo, e) {
        e.stopPropagation();
        this.resultado = await this.apiService.FavAdd(articulo.codigo);
        articulo.isWishlist = true;
        this.global.cambio = true;
    }

    // Quitar de favoritos
    async FavDel(articulo, e) {
        e.stopPropagation();
        this.resultado = await this.apiService.FavDel(articulo.codigo);
        articulo.isWishlist = false;
        this.global.cambio = true;
    }

    inputChange(ev: any, unid, item) {
        let change = ev.target.value;
        let qty = 0;

        if (unid == 1) {
            item.unid1 = Number(change);
        } else {
            item.unid2 = Number(change);
        }

        qty = Number(change);
        console.log(qty);
    }

    changeQty(item, unid, change: number) {
        console.log(unid);
        console.log(item);
        let qty = 0;
        let control = 1;

        setTimeout(() => {
            if (unid == 1) {
                console.log("Unidad1");
                if (change < 0 && item.unid1 == 0) {
                    return;
                }
                item.unid1 = Number(item.unid1) + Number(change);
                console.log("Llega" + item.unid1);

                if (item.formaRedondeo == 4) {
                    item.unid2 = Math.ceil(item.unid1 * item.relacionUnidades);
                } else if (item.formaRedondeo == 6) {
                    item.unid2 = Math.round(item.unid1 * item.relacionUnidades * Number(this.global.decimalesUnidades074)) / Number(this.global.decimalesUnidades074);
                } else if (item.formaRedondeo == 8) {
                    item.unid2 = Math.round(item.unid1 * item.relacionUnidades);
                } else if (item.formaRedondeo == 1 || item.formaRedondeo == 2) {
                    item.unid2 = item.unid1 * item.relacionUnidades;
                } else if (item.formaRedondeo == 5) {
                    item.unid2 = item.unid1 * item.relacionUnidades;
                    item.unid1 = 0;
                }
            } else {
                console.log("Unidad2");
                if (change < 0 && item.unid2 == 0 && control == 2) {
                    return;
                }
                item.unid2 = Number(item.unid2) + Number(change);
                console.log("Llega" + item.unid2);

                if (item.formaRedondeo == 4) {
                    item.unid1 = Math.ceil(item.unid2 / item.relacionUnidades);
                } else if (item.formaRedondeo == 6) {
                    item.unid1 = Math.round((item.unid2 / item.relacionUnidades) * Number(this.global.decimalesUnidades074)) / Number(this.global.decimalesUnidades074);
                } else if (item.formaRedondeo == 8) {
                    item.unid1 = Math.round(item.unid2 / item.relacionUnidades);
                } else if (item.formaRedondeo == 1 || item.formaRedondeo == 2) {
                    item.unid1 = item.unid2 / item.relacionUnidades;
                } else if (item.formaRedondeo == 5) {
                    item.unid1 = 0;
                }
            }
            console.log(item);
        }, 5);
    }

    async addtocart(item) {
        console.log(item);
        this.resultado = await this.apiService.cartAdd(item);
        console.log(this.resultado);

        this.getCartItems(item.nombre);
    }

    async compartir(item1, e) {
        e.stopPropagation();
        console.log("chat");
        console.log(item1);
        this.global.itemChat = item1;
        this.router.navigate(["/tabs/tab2"]);
    }

    async getCartItems(nombre) {
        this.cartService.cart().then((products) => {
            this.cartProducts = products;
            const object = this.cartProducts.articulos;
            console.log("Articulos carro: " + object);
            this.global.artcart = Object.keys(object).length;
        });
        const toast = await this.toastController.create({
            message: nombre + " añadido a tu carrito.",
            duration: 2000,
        });
        toast.present();
    }

    // Back to previous page function
    dismiss() {
        this.modalController.dismiss({
            dismissed: true,
        });
    }
}
