import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, IonInfiniteScroll, ToastController } from '@ionic/angular';
import { Product } from '../../models/product.model';
import { ProductsService } from '../../services/products.service';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { CartComponent } from '../cart/cart.component';
import { ValueAccessor } from '@ionic/angular/dist/directives/control-value-accessors/value-accessor';
import { GlobalService } from '../../services/global.service';
import { ApiService } from '../../api.service';
import { PopoverController } from '@ionic/angular';
import { ShareComponent } from './../../components/share/share.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Carro } from './../../models/carro.model';
import { CartService } from './../../services/cart.service';
import { FilterComponent } from '../filter/filter.component';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  // List of Products  
  products: Product[];
  loadedProducts: Product[];
  productsFiltrado: Product[];
  items: Product[];
  cartProducts: Carro;
  searchTerm = '';
  resultado = '';
  imgruta = '';
  incremento = 10;
  rutaimagen = '';
  cartItems: any[] = [];
  total: any;
  showEmptyCartMessage: boolean = false;
  loading: any;
  nophoto = '';

  sizeXl = "4";
  sizeLg = "4";
  sizeMd = "4";
  sizeXs = "6";


  count = 0;
  control: Boolean = false;
  grid: Boolean = true;
  oneColumn: Boolean = false;
  list: Boolean = false;
  // Check is product available or not
  isProductAvailable: boolean = false;
  search : string;

  constructor(private cartService: CartService, public modalController: ModalController,
    private productsService: ProductsService, public global: GlobalService,
    private router: Router, public loadingController: LoadingController, public apiService: ApiService,
    public popoverController: PopoverController, public toastController: ToastController) { }

   ngOnInit() {

    this.products = this.global.products;
    this.items = [];
    this.productsFiltrado = [];
    this.nophoto = this.global.url + 'imagenes/NOFOTO.JPG';
    
    if ((this.products === undefined) || (this.products == null)) {
      this.presentLoading();
      this.getProductList();
    } else {
      // si ya se cargo cogemos direcamente los datos
      this.cargadatos();
    }

  }

  inputChange(ev: any, item) {
    let change = ev.target.value;
    let qty = 0;
    let control = 1;
    
    setTimeout(() => {
    if (this.global.unidadMedidaMiniaturas329 == 0) {
      
      qty = item.unid2;
      control = 2;
    } else if(this.global.unidadMedidaMiniaturas329 == 1) {
      qty = item.unid1;
    } else if(this.global.unidadMedidaMiniaturas329 == 2) {
      if(item.precioSobre == 'S'){
        qty = item.unid1;
      } else {
        qty = item.unid2;
        control = 2;
      }
    }
    if (change == item.unid1 && control == 1) {
      return;
    }
    if (change == item.unid2 && control == 2) {
      return;
    }

    qty = Number(change);

    console.log(qty);

    if (control === 1) {
      item.unid1 = qty;
    } else {
      item.unid2 = qty;
    }
    console.log(item.unid2);
  }, 5);
  }
  
  
  // infinity scroll
  loadData(event) {

    this.incremento = this.incremento + 10;
    event.target.complete();
  }

  onError(item) {
    console.log(item.imagen);
    item.imagen = this.nophoto;
  }
  
  ionViewDidEnter(){
    if(this.global.opciones !== null){
      this.filtra(this.global.opciones);
    }
   }

   // Abrir la página de filtros
  async openFilterPage() {
    const modal = await this.modalController.create({
      component: FilterComponent
    });
    modal.onDidDismiss().then((data) => {
      this.searchTerm = '';
      this.products = this.global.products;
      console.log(data);
      this.filtra(data.data);

      //this.products = this.productsFiltrado;
    });
    return await modal.present();
  }

  filtra(data){
    if (data != null) {
      console.log(data[0]);
      console.log(data[0].codigoInt);
      console.log(data[0].opcion.lower);
      // Filtro precio

      // recorremos los filtros
      for (let u = 0; u < data.length; u++) {

        if (data[u].codigoInt === 'Precio') {

          for (let i = 0; i < this.products.length; i++) {
            if ((this.products[i].tarifa.precio > data[u].opcion.lower) && (this.products[i].tarifa.precio < data[u].opcion.upper)) {
              // console.log(this.products[i]);
              this.productsFiltrado.push(this.products[i]);
              }// fin comprobar filtro

            }// fin bucle
            // pasan solo los filtrados
          this.products = this.productsFiltrado;
          this.productsFiltrado = [];


          }// fin if precio

        if (data[u].codigoInt === 'Familia') {

            for (let a = 0; a < this.products.length; a++) {
              /*console.log(this.products[i].familia['codigo']);
              console.log(data[u].opcion);*/
              if (this.products[a].familia.codigo === data[u].opcion) {
                // console.log(this.products[a]);
                this.productsFiltrado.push(this.products[a]);
                }// fin comprobar filtro

              }// fin bucle
              // pasan solo los filtrados
            this.products = this.productsFiltrado;
            this.productsFiltrado = [];
            console.log(this.products.length);
            console.log(this.products);
            }// fin if familia

        if (data[u].codigoInt === '01') {
          console.log(this.products.length);

          for (let b = 0; b < this.products.length; ++b) {

                if (this.products[b].caracteristicas != null) {
                  console.log(this.products[b].caracteristicas);
                  if (this.products[b].caracteristicas.carac01 === data[u].opcion) {
                  console.log(this.productsFiltrado.indexOf(this.products[b]));
                  if (this.productsFiltrado.indexOf(this.products[b]) === -1) {
                    console.log(this.products[b]);
                    this.productsFiltrado.push(this.products[b]);
                    }
                  }// fin comprobar filtro
                }// comporbamos que caracteristicas no es null, sino da error

                }// fin bucle
                // pasan solo los filtrados
          this.products = this.productsFiltrado;
          this.productsFiltrado = [];
              }// fin if 01

        if (data[u].codigoInt === '02') {
                console.log(this.products.length);

                for (let b = 0; b < this.products.length; ++b) {

                      if (this.products[b].caracteristicas != null) {
                        console.log(this.products[b].caracteristicas);
                        if (this.products[b].caracteristicas.carac02 === data[u].opcion) {
                        console.log(this.productsFiltrado.indexOf(this.products[b]));
                        if (this.productsFiltrado.indexOf(this.products[b]) === -1) {
                          console.log(this.products[b]);
                          this.productsFiltrado.push(this.products[b]);
                          }
                        }// fin comprobar filtro
                      }// comporbamos que caracteristicas no es null, sino da error

                      }// fin bucle
                      // pasan solo los filtrados
                this.products = this.productsFiltrado;
                this.productsFiltrado = [];
                    }// fin if 02



          }// fin bucle filtros
    }
  }


  async compartir(item1, e) {
    e.stopPropagation();

    console.log('chat');
    console.log(item1);
    this.global.itemChat = item1;
    this.router.navigate(['/tabs/tab2']);    

}

   // loading de datos
   presentLoading() {
    this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Cargando productos...'
    }).then((res) => {
      res.present();

      res.onDidDismiss().then((dis) => {
        console.log('Loading dismissed!', dis);
        this.control = true;
      });
    });

    setTimeout(() => {
      this.loadingController.dismiss().then((res) => {
      console.log('Loading dismissed!', res);
      }).catch((error) => {
      console.log('error', error);
      });
    }, 3000);
  }

  // Añadir a favoritos
  async FavAdd(articulo, e) {
    e.stopPropagation();
    console.log(articulo);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.products.length; i++) {
      if(this.products[i].codigo === articulo){
         (this.products[i]);
        this.resultado = await this.apiService.FavAdd(this.products[i].codigo);
         (this.resultado);
        this.products[i].isWishlist = true;
        this.global.cambio = true;
      }
    }

  }

  // Quitar de favoritos
  async FavDel(articulo, e) {
    e.stopPropagation();
    console.log(articulo);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.products.length; i++) {
      if(this.products[i].codigo === articulo){
        console.log(this.products[i]);
        this.resultado = await this.apiService.FavDel(this.products[i].codigo);
        console.log(this.resultado);
        this.products[i].isWishlist = false;
        this.global.cambio = true;
      }
    }

  }

  // Get List of Products
  getProductList() {
    this.productsService.productList().then(products => {
      this.products = products;
      this.global.products = products;
       (this.products);

      // quitamos la precarga
      this.loadingController.dismiss().then((res) => {
        console.log('Loading dismissed!', res);
      }).catch((error) => {
        console.log('error', error);
      });

      this.cargadatos();
    });
  }

  // cargamos los 25 datos iniciales
  cargadatos() {
    if ((this.products === undefined) || (this.products == null)) {
    } else {
      for (let i = 0; i < 25; i++) {  // limita a los items ue necesites .
        /*console.log(this.products);
        console.log(this.products[this.count]);*/
        this.items.push(this.products[this.count]);   // EL JSON que quieres listar
        this.count++;
      }
    // this.loadingController.dismiss();
    }
  }

  // busqueda antigua
  getProducts(ev: any) {
    if (this.products == null || this.products.length === 0) {
        return;
      } else {
        if ( this.searchTerm.length >= 3 ) {
        this.items = this.products.filter(item => {
          return item.descripcion.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
        });
      } else {

        this.items = [];
        this.getProductList();

        return;
      }
    }
  }
  // Go to product details page
  async goToProductDetails(item1) {
     
    const modal = await this.modalController.create({
      component: ProductDetailsComponent,
      componentProps: { producto: item1 }
    });
    return await modal.present();
  }

  loadImage(variable){
    console.log(variable);
    const image = new Image();
    this.rutaimagen = variable.split('\\').pop().split('/').pop();
    this.imgruta = this.rutaimagen.split('.').slice(0, -1).join('.');
    this.rutaimagen = this.global.url + 'imagenes/' + this.imgruta.toUpperCase();
    image.src = this.rutaimagen + '.jpg';
    console.log(image.src);
    if (image.width === 0) {
      (this.rutaimagen + '.JPG');
     image.src = this.rutaimagen + '.JPG';
     if (image.width === 0) {
       return this.nophoto;
     } else {
      this.imgruta = this.rutaimagen + '.JPG';
      return this.imgruta;
     }
    }
 }
 
 changeQty(item, i, change: number){
  console.log(i);
  let qty = 0;
  let control = 1;
  
  setTimeout(() => {
    if (this.global.unidadMedidaMiniaturas329 == 0) {
      
      qty = item.unid2;
      control = 2;
    } else if(this.global.unidadMedidaMiniaturas329 == 1) {
      qty = item.unid1;
    } else if(this.global.unidadMedidaMiniaturas329 == 2) {
      if(item.precioSobre == 'S'){
        qty = item.unid1;
      } else {
        qty = item.unid2;
        control = 2;
      }
    }
    if (change < 0 && item.unid1 == 0 && control == 1) {
      return;
    }
    if (change < 0 && item.unid2 == 0 && control == 2) {
      return;
    }

    qty = Number(qty) + Number(change);

    console.log(qty);

    if (control === 1) {
      item.unid1 = qty;
    } else {
      item.unid2 = qty;
    }
    console.log(item.unid2);
  }, 5);
}

async addtocart(articulo, i){
  console.log(articulo);
  console.log(i);

  articulo  = await this.apiService.dameUnidades(articulo);

  this.resultado = await this.apiService.cartAdd(articulo);
  console.log(this.resultado);
  this.getCartItems(articulo.nombre);

}

async getCartItems(nombre) {
  this.cartService.cart().then((products) => {
    this.cartProducts = products;
    const object = this.cartProducts.articulos;
    console.log('Articulos carro: ' + object);
    this.global.artcart = Object.keys(object).length;
  });
  const toast = await this.toastController.create({
    message: nombre + ' añadido a tu carrito.',
    duration: 2000
  });
  toast.present();
}

  // Abrir la página de filtros
  /*async openFilterPage() {
    const modal = await this.modalController.create({
      component: FilterComponent
    });
    return await modal.present();
  }*/

  // Go to cart page function
  async gotoCartPage() {
    this.dismiss();
    const modal = await this.modalController.create({
      component: CartComponent
    });
    modal.onDidDismiss().then((data) => {
        this.getProductList();
        
      })
    return await modal.present();
  }

  // Back to previous page function
  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  showOneColumn() {
    this.oneColumn = true;
    this.grid = false;
    this.list = false;
    

    this.sizeLg = "12";
    this.sizeMd = "12";
    this.sizeXl = "12";
    this.sizeXs = "12";
}

// En Grid
showGrid() {
    this.grid = true;
    this.oneColumn = false;
    this.list = false;

    this.sizeXl = "4";
    this.sizeLg = "4";
    this.sizeMd = "4";
    this.sizeXs = "6";
}

// En lista
showList() {
    this.list = true;
    this.grid = false;
    this.oneColumn = false;
}

}
