import { Carro } from './../../models/carro.model';
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { StorageService } from '../../services/storage.service';
import { CheckoutComponent } from '../checkout/checkout.component';
import { CartService } from 'src/app/services/cart.service';
import { ApiService } from '../../api.service';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {

  cartProducts: Carro;
  total = 0;
  resultado = '';
  nophoto = '';
  texto = '';

  constructor(public global: GlobalService, public alertController: AlertController, public modalController: ModalController, public cartService: CartService, public apiService: ApiService) { }

  ngOnInit() {
    this.getCartItems();
    this.nophoto = this.global.url + 'imagenes/NOFOTO.JPG';
  }

  // Get Cart Items From Storage
  getCartItems() {
    this.cartService.cart().then(async (products) => {
      this.cartProducts = products;
      this.global.cartProducts = products;
      console.log(this.cartProducts);
      const object = this.cartProducts.articulos;
      for (let i = 0; i < object.length; i++) {
        console.log(object[i]);
        object[i] = await this.apiService.dameUnidades(object[i]);
      }
      
      console.log('Articulos carro: ' + object);
      this.global.artcart = Object.keys(object).length;
    });
  }
  dametexto(item){
  this.texto = this.apiService.dametexto(item);
  if(this.texto ==  'Unidades'){
    return('Und.');
  }else if(this.texto ==  'Cajas'){
    return('Caj.');
  }else{
    return('Und.');
  }
  }
  inputChange(ev: any, unid, item) {
    let change = ev.target.value;
    let qty = 0;
    
    if(unid == 1){
    item.unid1 = Number(change);
    }else{
      item.unid2 = Number(change);
    }

    qty = Number(change);
    console.log(qty);
  }
  
 async changeQty(item, unid, change: number){
  console.log(unid);
  console.log(item);
  let qty = 0;
  let control = 1;
  
  setTimeout(async () => {
  if(unid == 1){
    console.log('Unidad1');
    if (change < 0 && item.unid1 == 0 ) {
      return;
    }
    item.unid1 = Number(item.unid1) + Number(change);
    console.log('Llega'+item.unid1);

    if (item.formaRedondeo == 4 ) {
      item.unid2 = Math.ceil(item.unid1 * item.relacionUnidades);
    } else if (item.formaRedondeo == 6 ) {
      item.unid2 = Math.round((item.unid1 * item.relacionUnidades) * Number(this.global.decimalesUnidades074)) / Number(this.global.decimalesUnidades074);
    } else if (item.formaRedondeo == 8 ) {
      item.unid2 = Math.round(item.unid1 * item.relacionUnidades);
    } else if ( item.formaRedondeo == 1 || item.formaRedondeo == 2) {
      item.unid2 = item.unid1 * item.relacionUnidades;
    } else if ( item.formaRedondeo == 5) {
      item.unid2 = item.unid1 * item.relacionUnidades;
      item.unid1 = 0;
    }

  }else{
    console.log('Unidad2');
    if (change < 0 && item.unid2 == 0 && control == 2) {
      return;
    }
    item.unid2 = Number(item.unid2) + Number(change);
    console.log('Llega'+item.unid2);

    if (item.formaRedondeo == 4 ) {
      item.unid1 = Math.ceil(item.unid2 / item.relacionUnidades);
    } else if (item.formaRedondeo == 6 ) {
      item.unid1 = Math.round((item.unid2 / item.relacionUnidades) * Number(this.global.decimalesUnidades074)) / Number(this.global.decimalesUnidades074);
    } else if (item.formaRedondeo == 8 ) {
      item.unid1 = Math.round(item.unid2 / item.relacionUnidades);
    } else if ( item.formaRedondeo == 1 || item.formaRedondeo == 2) {
      item.unid1 = item.unid2 / item.relacionUnidades;
    } else if ( item.formaRedondeo == 5) {
      item.unid1 = 0;
    }

  }
    console.log(item);
    
    this.resultado = await this.apiService.cartAdd(item);
    console.log(this.resultado);

    this.getCartItems();
    
  }, 5);
}

  // Remove Product From Cart
  async removeProduct(product, index) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Quitar artículo',
      message: '¿Desea quitar este artículo del pedido?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cancelado');
          }
        }, {
          text: 'Quitar',
          handler: () => {
            this.apiService.cartDel(product.codigo.replace(/ /g, ''));
            console.log('Confirmado');
            setTimeout(() => {
              this.getCartItems();
            }, 150);
          }
        }
      ]
    });

    await alert.present();
  }

  onError(item) {
    console.log(item.imagen);
    item.imagen = this.nophoto;
  }
  // Go to checkout page
  goToCheckout() {

    this.alertController.create({
      header: 'Realizar pedido',
      subHeader: 'Confime para continuar',
      message: '¿Está seguro de realizar este pedido?',
      buttons: [
        {
          text: 'Si',
          handler: () => {
            console.log('ha dicho que si, seguimos con el pedido');
            this.pedido();
          }
        },
        {
          text: 'No',
          handler: () => {
            console.log('El pedido queda aún como borrador');
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  async pedido() {
    // this.dismiss();
    await this.apiService.cartClose().then((resultado) => {

      console.log("Aqui resultado"  + resultado);
      console.log(resultado);
      if (resultado == '202') {
          this.dismiss();
          this.global.artcart = 0;
          this.getCartItems();
          this.presentAlert('Pedido realizado correctamente.');
        } else {
          this.presentAlert('Error cerrando el pedido. Revise la conexión e intentelo de nuevo mas tarde.');
        }
    });
    
  }


  // Go to product details page
  async goToProductDetails(item1) {
    // console.log(codigoP);
    const modal = await this.modalController.create({
      component: ProductDetailsComponent,
      componentProps: { producto: item1 }
    });
    return await modal.present();
  }

  async presentAlert(err) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.global.titulo,
      message: err,
      buttons: ['OK']
    });

    await alert.present();
  }

  // Back to previous page options
  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
